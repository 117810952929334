import React from 'react';
import Flex from '../Flex';
import Logo from '../Logo';
import Text from '../Text';
function LogoText({ text, ...props }) {
    return (React.createElement(Flex, Object.assign({ alignItems: "center" }, props),
        React.createElement(Logo, { color: "marvel", display: "block", hasTransition: false, height: 25, mr: "3px", width: 60 }),
        React.createElement(Text, { color: "smokeExtraDark", fontSize: 10, fontWeight: "500", letterSpacing: 2, mb: 1, textTransform: "uppercase" }, text)));
}
export default LogoText;
