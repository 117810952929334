import styled from 'styled-components';
import { color, position, layout } from 'styled-system';
import themeGet from '@styled-system/theme-get';
import { transform, overflow } from '../customProperties';
export const SidebarElement = styled.div `
  ${color};
  ${layout};
  ${overflow};
  ${position};
  ${transform};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: flex-start;
  justify-content: space-between;
  transition: all 600ms ${themeGet('easings.smooth.string')};
  box-shadow: ${themeGet('shadows.borders.right')};
  position: fixed;
`;
