import React, { useMemo } from 'react';
import { Link as GatsbyLink, useStaticQuery, graphql } from 'gatsby';

import { Dashboard, DashboardBox } from '@marvelapp/ui-internal';
import { Box, Sidebar, LogoText, Link } from '@marvelapp/ui';

import generateSidebarLinks from '../utils/generateSidebarLinks';

export default function Page({ children, location }) {
  const { allSitePage } = useStaticQuery(
    graphql`
      query {
        allSitePage {
          edges {
            node {
              path
            }
          }
        }
      }
    `
  );
  const pages = allSitePage.edges.map((edge) => edge.node.path);
  const sidebarLinks = useMemo(() => generateSidebarLinks(pages), [pages]);
  const { pathname } = location;

  return (
    <>
      <Sidebar maxHeight="100%" overflow="auto" sidebarWidth={300}>
        <Box position="relative" pt={[5, 40]} px={[5, 5, 6]} width={1}>
          <GatsbyLink to="/">
            <LogoText
              color="marvel"
              marginBottom={[4, 6]}
              padding={1}
              text="Docs"
            />
          </GatsbyLink>
          <SidebarLinks currentPath={pathname} links={sidebarLinks} />
        </Box>
      </Sidebar>
      <Dashboard sidebarWidth={300}>
        <Box maxWidth={780}>
          <DashboardBox>{children}</DashboardBox>
        </Box>
      </Dashboard>
    </>
  );
}

function SidebarLinks({ links, currentPath }) {
  const mainLinks = Object.keys(links);

  return mainLinks.map((link) => {
    const { to, name, sublinks, parentPath } = links[link];

    return (
      <Box key={to}>
        <Link
          as={GatsbyLink}
          fontSize={16}
          fontWeight={500}
          kind="licorice"
          marginBottom={3}
          to={to}
        >
          {name}
        </Link>
        {sublinks && currentPath.startsWith(`/${parentPath}`) && (
          <Box marginBottom={4} marginTop={2}>
            {sublinks.map((sublink) => (
              <Box key={sublink.to} marginBottom={3} paddingLeft={2}>
                <Link as={GatsbyLink} fontSize={3} kind="black" to={sublink.to}>
                  {sublink.name}
                </Link>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    );
  });
}
