import styled, { css } from 'styled-components';
import { colors } from '@marvelapp/theme';
import { border, color, layout, opacity, space, typography, } from 'styled-system';
import { fill, hoverBg, hoverColor, hoverFill, hoverOpacity, pointerEvents, whiteSpace, } from '../customProperties';
import { underlineColor } from './colors';
export const LinkElement = styled.a `
  ${border};
  ${color};
  ${fill};
  ${layout};
  ${opacity};
  ${space};
  ${typography};
  ${whiteSpace};
  ${pointerEvents};

  cursor: pointer;
  text-decoration: none;
  transition-duration: 150ms;

  &.${'active'} {
    color: ${colors.licorice};
    font-weight: 500;

    &:hover {
      color: ${colors.licorice};
    }
  }

  &:hover {
    ${hoverBg};
    ${hoverColor};
    ${hoverFill};
    ${hoverOpacity};
  }

  ${(props) => props.hasUnderline &&
    css `
      background-position: 0 95%;
      background-repeat: repeat-x;
      background-size: 2px 2px;

      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 50%,
        ${underlineColor[props.color]} 50%
      );

      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0) 75%,
          ${underlineColor[props.color]} 75%
        );
      }
    `};
`;
