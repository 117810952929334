import styled from 'styled-components';
import { Box } from '@marvelapp/ui';
import { breakpoints, containerWidths } from '@marvelapp/theme';
export const DashboardBoxElement = styled(Box) `
  width: 100%;
  max-width: ${containerWidths[0]};

  @media (min-width: ${(props) => props.hasSidebar ? breakpoints[0] : containerWidths[0]}) {
    width: ${containerWidths[0]};
    max-width: 100%;
  }

  @media (min-width: ${(props) => props.hasSidebar ? breakpoints[1] : containerWidths[1]}) {
    width: ${containerWidths[1]};
    max-width: 100%;
  }

  @media (min-width: ${(props) => props.hasSidebar ? breakpoints[2] : containerWidths[2]}) {
    width: ${containerWidths[2]};
    max-width: 100%;
  }

  @media (min-width: ${(props) => props.hasSidebar ? breakpoints[3] : containerWidths[3]}) {
    width: ${containerWidths[3]};
    max-width: 100%;
  }

  @media (min-width: ${(props) => props.hasSidebar ? breakpoints[4] : containerWidths[4]}) {
    width: ${containerWidths[4]};
    max-width: 100%;
  }

  @media (min-width: ${(props) => props.hasSidebar ? breakpoints[5] : containerWidths[5]}) {
    width: ${containerWidths[5]};
    max-width: 100%;
  }

  @media (min-width: ${(props) => props.hasSidebar ? breakpoints[6] : containerWidths[6]}) {
    width: ${containerWidths[6]};
    max-width: 100%;
  }

  @media (min-width: ${(props) => props.hasSidebar ? breakpoints[7] : containerWidths[7]}) {
    width: ${containerWidths[7]};
    max-width: 100%;
  }

  @media (min-width: ${(props) => props.hasSidebar ? breakpoints[8] : containerWidths[8]}) {
    width: ${containerWidths[8]};
    max-width: 100%;
  }

  @media (min-width: ${(props) => props.hasSidebar ? breakpoints[9] : containerWidths[9]}) {
    width: ${containerWidths[9]};
    max-width: 100%;
  }
`;
DashboardBoxElement.defaultProps = {
    hasSidebar: true,
};
