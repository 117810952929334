import pattern1 from './patterns/1.gif';
import pattern2 from './patterns/2.gif';
import pattern3 from './patterns/3.gif';
import pattern4 from './patterns/4.gif';
import pattern5 from './patterns/5.gif';
import pattern6 from './patterns/6.gif';
import pattern7 from './patterns/7.gif';
import pattern8 from './patterns/8.gif';
export const patterns = [
    pattern1,
    pattern2,
    pattern3,
    pattern4,
    pattern5,
    pattern6,
    pattern7,
    pattern8,
];
