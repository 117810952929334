export default function generateSidebarLinks(links) {
  const nonFeaturePages = [
    '/dev-404-page/',
    '/404/',
    '/',
    '/writing/',
    '/404.html',
  ];
  return links.sort().reduce((acc, path) => {
    const isFeaturePage = !nonFeaturePages.includes(path);
    if (isFeaturePage) {
      const urlParts = path.substr(1).split('/');
      const [link, sublink] = urlParts.map((part) => formatLinkName(part));

      // create top level link if it doesn't exist yet
      if (!acc[link]) {
        acc[link] = {
          name: link,
          to: path,
          parentPath: urlParts[0],
          sublinks: [],
        };
      }

      acc[link].sublinks.push({ name: sublink, to: path });
    }
    return acc;
  }, {});
}

function formatLinkName(pageName) {
  return pageName
    .split('-')
    .map((str) => str.charAt(0).toUpperCase() + str.substr(1))
    .join(' ');
}
