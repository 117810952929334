import { colors } from '@marvelapp/theme';
import { hexToRGB } from '../utils';
export const underlineColor = {
    slate: hexToRGB(colors.slate, 0.35),
    marvel: hexToRGB(colors.marvel, 0.5),
    red: hexToRGB(colors.red, 0.5),
    white: hexToRGB(colors.white, 0.5),
    green: hexToRGB(colors.green, 0.5),
    purple: hexToRGB(colors.purple, 0.5),
    yellowDark: hexToRGB(colors.yellowDark, 0.5),
    marvelDark: hexToRGB(colors.marvelDark, 0.5),
};
