import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@marvelapp/ui';
function Dashboard({ children, hasSidebar, isEmpty, minHeight, paddingBottom = 4, paddingTop = 4, sidebarWidth, topNotification, }) {
    return (React.createElement(Flex, { flexDirection: "column", minHeight: minHeight, pl: hasSidebar ? [0, 0, 0, sidebarWidth] : 0 },
        topNotification,
        React.createElement(Flex, { alignItems: "center", flex: 1, flexDirection: "column", justifyContent: isEmpty || children ? 'flex-start' : 'center', paddingBottom: paddingBottom, paddingTop: paddingTop, width: 1 }, children)));
}
Dashboard.propTypes = {
    children: PropTypes.node,
    hasSidebar: PropTypes.bool,
    isEmpty: PropTypes.bool,
    minHeight: PropTypes.any,
    sidebarWidth: PropTypes.number,
};
Dashboard.defaultProps = {
    hasSidebar: true,
    isEmpty: false,
    minHeight: '100vh',
};
export default Dashboard;
